<template>
  <div class="appointment">
    <div class="card" ref="myRef">
      <div class="info" v-if="firstFlag">
        <div class="logo"><img src="../assets/image/logo.png" alt="" /></div>
        <div class="title">恭喜您预约成功</div>
        <div class="appointment_conotent">
          <div class="appointment_conotent_left">预约信息</div>
          <div class="info_con">
            <div>
              <span>预约姓名：</span
              ><label>{{ appointmentInfo.username }}</label>
            </div>
            <div>
              <span>联系方式：</span><label>{{ appointmentInfo.phone }}</label>
            </div>
            <div>
              <span>预约时间：</span><label>{{ appointmentInfo.time }}</label>
            </div>
            <div>
              <span>预约门店：</span><label>{{ appointmentInfo.store }}</label>
            </div>
          </div>
        </div>
        <van-button
          class="info_con_btn"
          round
          block
          type="primary"
          native-type="submit"
          color=" rgba(99, 49, 240, 1)"
          @click="opentxLocation"
          v-if="firstFlag"
          >一 键 导 航</van-button
        >
      </div>
      <img
        :src="imgUrl"
        alt=""
        class="currentImg"
        v-if="!firstFlag"
        @click="opentxLocation"
      />
      <span v-if="!firstFlag" class="currentSpan">*长按保存到店出示</span>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import wx from "weixin-js-sdk";
import { useRouter } from "vue-router";
import { getCode } from "../utils/api.js";
import html2canvas from "html2canvas";
export default {
  name: "Appointment",
  components: {},
  setup() {
    const myRef = ref(null);
    const router = useRouter();
    onMounted(() => {
      init();
      getOpenid(sessionStorage.getItem("code"));
      setTimeout(function() {
        generatorImage(); // 长按保存
      }, 0);
    });
    const generatorImage = () => {
      html2canvas(myRef.value).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        state.imgUrl = dataURL;
        // console.log("eeeeee", dataURL);
        // let link = document.createElement("a");
        // link.href = canvas.toDataURL(); //下载链接
        // link.setAttribute("download", "未来战场.png");
        // link.style.display = "none"; //a标签隐藏
        // document.body.appendChild(link);
        // link.click();
        state.firstFlag = false;
      });
    };
    const state = reactive({
      appointmentInfo: {},
      imgUrl: "",
      firstFlag: true,
    });
    const init = () => {
      // 提取预约信息
      state.appointmentInfo = JSON.parse(
        sessionStorage.getItem("appointmentInfo")
      );
    };
    const getOpenid = (code) => {
      // `shop_id`, `shop_name`, `customer_name`, `customer_phone`, `appointment_date`
      let data = {
        code: code,
        shop_id: "tvSda6cMGJcNiTCqX2wr4f",
        shop_name: state.appointmentInfo.store,
        customer_name: state.appointmentInfo.username,
        customer_phone: state.appointmentInfo.phone,
        appointment_date: state.appointmentInfo.time,
      };

      getCode(data).then(() => {});
    };
    const opentxLocation = () => {
      console.log("eeeeeeee");
      wx.ready(function() {
        wx.openLocation({
          // 39.921689，116.605137
          latitude: 39.921689, // 纬度，浮点数，范围为90 ~ -90
          longitude: 116.605137, // 经度，浮点数，范围为180 ~ -180。
          name: "未来战场北京常营店", // 位置名
          address: "北京市朝阳区常营体育公园公园西南角", // 地址详情说明
          scale: 18, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      });
    };
    const goBack = () => {
      router.go(-1);
    };
    return {
      opentxLocation,
      ...toRefs(state),
      goBack,
      myRef,
    };
  },
};
</script>

<style lang="scss">
.appointment {
  background: #fff;
  .card {
    width: 85%;
    height: 600px;
    overflow: hidden;
    margin: 30px auto;
    box-shadow: 5px 5px 10px #ccc;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 222;
    width: 100%;
    height: 100%;
    background: url("../assets/image/wlzc.png") no-repeat;
    background-size: 100% 100%;
    .logo {
      width:32%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 30px;
      left: 30px;
      img {
        width: 100%;
      }
    }
    .title {
      color: #fff;
      font-size: 30px;
      font-weight: bolder;
      margin-top: 30px;
    }
    .appointment_conotent {
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 22px;
      box-sizing: border-box;
      margin-top: 30px;
      .appointment_conotent_left {
        font-size: 22px;
        color: #fff;
        font-weight: bolder;
        max-width: 50px;
        min-width: 50px;
        width: 50px;
        margin-right: 22px;
      }
      .info_con {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #dadada;
        padding-left: 22px;
        div {
          width: auto;
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          color: #fff;
          font-weight: bold;
          font-size: 15px;
        }
      }
    }

    .info_con_btn {
      width: 90%;
      margin-top: 50px;
    }
  }
  .currentImg {
    width: 100%;
    height: 96%;
    object-fit: cover;
  }
  .currentSpan {
    color: rgba(62, 46, 236, 0.7);
    font-size: 14px;
    margin-top: 5px;
  }
}
</style>
